// se refiere al overlay del modal, es decir el contenedor que contiene a la ventana modal el cual es de color negro con algo de transparencia.
.base-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    z-index: 9999999;
}

// la ventana por defecto para los modales de makemake
.default-modal-window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);
    padding: 20px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;

    $themes: (
        "clasico":(background: #f1f1ff,
        ),
        "alto-contraste":(background: var(--background-color),
        ),
    );

@include ThemesProperties($themes);
}