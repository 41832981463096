/*=======================================
  Menu
  En este archivo se encuentran los 
  estilos del menu responsive
=========================================*/

@use "sass:map";

.close-container-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

@mixin containerMenu {
  box-shadow: 3px 0 20px #4d4d4d;
  width: 80%;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: all 0.3s ease;
  background-color: var(--background-color);
  transition: all 0.2s ease-in;
  z-index: 101;
}

.container-menu-individual {
  @include containerMenu;
  max-width: 280px;
  font-size: 1em;
  right: -300px;
}

.container-menu {
  @include containerMenu;
  max-width: 480px;
  font-family: $secondary-font;
  font-size: 0.85em;
  left: -500px;

  h2 {
    font-size: 1.8em;
    color: var(--secondary-color);
  }
  h3 {
    font-size: 1.2em;
    font-weight: 600;
  }
  li {
    font-weight: 600;
    transition: all 0.2s ease-in;
    &:hover {
      transform: scale(1.03);
    }
    p {
      line-height: 1.15;
      cursor: pointer;
      border-radius: 5px !important;
    }
  }
}

.nuevo-menu {
  #explorar-menu {
    box-shadow: 0px 0px 10px 0px rgba(10, 8, 8, 0.4);
    background-color: #fff;
    $themes:(
      "alto-contraste":(
        border-bottom:1px solid var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);

  }

  #menu-subject-title {
    text-transform: uppercase;
    letter-spacing: 0.15em;
  }
}

.appear-container-menu {
  left: 0;
  animation-duration: 0.2s;
  animation-name: showmenu;
}

.appear-container-menu-individual {
  right: 0;
  animation-duration: 0.2s;
  animation-name: showmenuIndividual;
}
@keyframes showmenu {
  from{
    left: -500px;
  }

  to{
    left: 0;
  }
}

@keyframes showmenuIndividual {
  from {
    right: -300px;
  }

  to {
    right: 0;
  }
}
.main-menu--age {
  li {
    p {
      color: #fff;
      background: #fff;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      cursor: pointer;
      text-align: center;
    }

    $ageProperties:(
      child1:(
        "clasico":(
          background-color:#8cc63f,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child2:(
        "clasico":(
          background-color:#c140ff,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child3:(
        "clasico":(
          background-color:#f2580c,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child4:(
        "clasico":(
          background-color:#b2ae1c,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child5:(
        "clasico":(
          background-color:#6680e3,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child6:(
        "clasico":(
          background-color:#f2b705,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
      child7:(
        "clasico":(
          background-color:#cd3f61,
          color:#fff
        ),
        "alto-contraste":(
          background-color:var(--background),
          color:var(--onbackground-primary)
        )
      ),
    );

  @for $i from 1 through 7{
    &:nth-child(#{$i}) {
      p {
        @include ThemesProperties(map.get($ageProperties, "child#{$i}"));
        border:solid 1px var(--onbackground-fourth);
      }
    }
  }

}
}

.main-menu--level-2 {
  li {
    p {
      width: 100%;
      position: absolute;
      text-align: center;
      background-repeat: no-repeat;
      overflow: hidden;
      bottom: -30px;
      font-size: 0.9em !important;
      padding: 15px 0;
    }

    a {
      display: block;
      position: relative;
      padding-top: 100px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.562);
    }

    span {
      color: #000;
    }

    &:nth-child(1) {
      a {
        background: url("/img/icons/nivel1-gris.png") center top/90% no-repeat;
      }
    }
    &:nth-child(2) {
      a {
        background: url("/img/icons/nivel2-gris.png") center top/80% no-repeat;
      }
    }
    &:nth-child(3) {
      padding-top: 50px;
      a {
        background: url("/img/icons/nivel3-gris.png") center top/80% no-repeat;
      }
    }
  }
}

.main-menu--level {
  .item-reading-level {
    font-size: 1.15em;
    background-color: #fff;
    border: 1px solid #b8b8b8;
    padding-left: 34px !important;
    background-repeat: no-repeat;
    overflow: hidden;
    span {
      color: #000;
    }
  }
}

.main-menu--gender {
  .item-gender {
    border: 1px solid var(--onbackground-fourth);
    overflow: hidden;
    padding-left: 36px !important;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    &:before {
      background-image: url("/img/icons/spriteMenu.png");
      content: "";
      height: 20px;
      left: 21px;
      position: absolute;
      width: 20px;
    }
  }
  .item-gender_libro-album {
    &:before {
      background-position: -194px -240px;
    }
  }
  .item-gender_cuento-ilustrado {
    &:before {
      top: 38%;
      background-position: -135px -270px;
    }
  }
  .item-gender_comic {
    &:before {
      background-position: -222px -270px;
    }
  }
  .item-gender_poesia {
    &:before {
      background-position: -136px -240px;
    }
  }
  .item-gender_cronica {
    &:before {
      background-position: -194px -270px;
    }
  }
  .item-gender_interactivo {
    &:before {
      background-position: -222px -240px;
    }
  }
  .item-gender_didactico {
    &:before {
      background-position: -277px -240px;
    }
  }
  .item-gender_teatro {
    &:before {
      background-position: -248px -270px;
    }
  }
  .item-gender_cuento {
    &:before {
      background-position: -165px -270px;
    }
  }
  .item-gender_novela {
    &:before {
      background-position: -165px -240px;
    }
  }
  .item-gender_informativo {
    &:before {
      background-position: -248px -240px;
    }
  }
}

.main-menu--subject {
  .item-subject {
    text-align: center;
    padding-left: 0px !important;
    cursor: pointer;
    padding-top: 105px;
    padding-bottom: 0px;
    border: none;
    &:before {
      content: "";
      background-image: url("/img/icons/spriteMenu.png");
      position: absolute;
      top: 0;
      width: 105px;
      height: 105px;
      margin: 0 auto;
      left: 0;
      right: 0;
    }
  }
  .item-subject_narrativa {
    &:before {
      background-position: -116px -2px;
    }
  }
  .item-subject_letras-y-numeros {
    &:before {
      background-position: -343px -2px;
    }
  }
  .item-subject_cancion-y-poesia {
    &:before {
      background-position: -3px -2px;
    }
  }
  .item-subject_ciencias {
    &:before {
      background-position: -230px -2px;
    }
  }
  .item-subject_historia-y-ensayo {
    &:before {
      background-position: -230px -116px;
    }
  }
  .item-subject_artes-y-manualidades {
    &:before {
      background-position: -3px -116px;
    }
  }
}

.scroll-menu {
  height: calc(100% - 122px);
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-track {
    //box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #e6e6e6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--secondary-color);
  }
}

.main-menu--planes {
  li {
    a {
      position: relative;
      color: initial;
      &:hover {
        color: initial;
      }
    }
    p {
      text-align: center;
      z-index: 5;
      background-color: var(--primary-color);
      position: absolute;
      bottom: -5px;
      border-radius: 8px !important;
      font-size: 1em !important;
    }
    .img-plan {
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 50%;
      background-position: 50% 174%;
      // border: 4px solid #D7D3D3;
      border: 4px solid #fff;
      box-shadow: 0px 0px 5px 1px #0009;
    }
  }
}

@media only screen and (min-width: 526px) {
  .main-menu--gender {
    .item-gender {
      padding-left: 50px !important;
      text-align: left;
      white-space: break-spaces;
      &:before {
        left: 34px;
      }
    }
  }

  .main-menu--subject {
    .item-subject {
      text-align: center;
      background-size: 95px;
      background-position: center 0;
      background-repeat: no-repeat;
      padding-top: 105px;
      padding-left: 0px !important;
      cursor: pointer;
      padding-bottom: 0px;
      border: none;
    }
  }

  .main-menu--level {
    .item-reading-level {
      background-color: var(--primary-color) !important;
      color: #000 !important;
      cursor: pointer;
      padding-left: 50px;
      border: none;
      position: relative;
      &:before {
        content: "";
        background-image: url("/img/icons/spriteMenu.png");
        position: absolute;
        width: 96px;
        height: 56px;
        left: 10px;
        margin-top: -1rem;
      }
    }
    .item-reading-level_primeros-lectores {
      &:before {
        background-position: -5px -318px;
      }
    }
    .item-reading-level_lectores-intermedios {
      &:before {
        background-position: -5px -397px;
      }
    }
    .item-reading-level_lectores-avanzados {
      &:before {
        background-position: -5px -238px;
      }
    }
  }

  .main-menu--level-2 {
    .item-reading-level {
      background-color: var(--primary-color) !important;
      color: #000 !important;
      cursor: pointer;
      padding-left: 50px;
      border: none;
      position: relative;
      &:before {
        content: "";
        background-image: url("/img/icons/spriteMenu.png");
        position: absolute;
        width: 96px;
        height: 56px;
        left: 10px;
        margin-top: -1rem;
      }
    }
  }
}

@media only screen and (min-width: 381px) {
  .main-menu--level-2 {
    li {
      &:nth-child(1) {
        p {
          padding: 20px 0;
        }
      }
      p {
        padding: 10px 0;
      }
    }
  }
}

@media only screen and (min-width: 449px) {
  .main-menu--level-2 {
    li {
      p {
        padding: 10px 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 526px) {
  .main-menu--level-2 {
    li {
      &:nth-child(3) {
        padding-top: 0px;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .scroll-menu {
    height: calc(100% - 72px);
  }

  .main-menu--level-2 {
    li {
      p {
        font-size: initial;
      }
    }
  }

  .main-menu--planes {
    p {
      font-size: inherit !important;
    }
    .img-plan {
      width: 125px !important;
      height: 125px !important;
    }
  }
}
