/*=====================================================
    TITLES
    En este archivo se encuentran los estilos de 
    los títulos principales que estan estandarizados. 
    Las secciones que tienen estos títulos son club mkmk,
    qué es?, blog y la tienda.
 ====================================================== */

@mixin textTitle($fontSize: 80px){
  font-family: var(--secondary-font);
  font-size: $fontSize;
  font-weight: 600;
  line-height: 1.3em;
}


.titlePage{
  padding: 30px 0;
  background-image: url('/img/backs/back-titles.svg');
  background-size: cover; 
  background-position: center;
  $themes:(
    "alto-contraste":(
      border-bottom: 1px solid var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  h1{
    @include textTitle(1.5em);
  }

  p{
    font-size: 0.9em;
  }

  @media screen and (min-width: 992px) {
    height: 165px;
    h1{
      @include textTitle(50px);
    }
  }

}

.main-title{
  padding: 20px 0;
  $themes:(
    "alto-contraste":(
      border-bottom: 1px solid var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  h1{
    @include textTitle(2.5em);
  }
  
  img{
    margin: auto;
  }
} 

.second-title{
  $themes:(
    "alto-contraste":(
      border-bottom: 1px solid var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  h1{
    @include textTitle(2.5em);
  }
}

.title-que-es{
  padding: 90px 0 10px 0;
}

.club-bg{
  $themes:(
    "clasico":(
      background: linear-gradient(89.95deg, #6666CC 0.04%, #82559E 79.9%)
    ),
    "alto-contraste": (
      background: var(--surface-color)
    )
  );
  @include ThemesProperties($themes);

  .club-img{
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.blog-bg{
  background-color: var(--scarlet-color);

  p{
    font-family: var(primary-font);
    font-weight: 500;
    font-size: 1.3em;
    line-height: 32px;
  }
}

.tienda-bg{
  p{
    font-weight: 500;
    font-size: 1em;
  }
}

@media screen and (min-width: 768px) {
  .blog-bg{
    background-image: url('/img/headerMake2.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media screen and (min-width: 992px) {

  .main-title{
    height: 280px;
    padding: 0;
    h1{
      font-size: 80px !important;
      line-height: 100px !important;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2 !important;
      text-overflow: ellipsis;
    }
  } 

  .second-title{
    height: 185px;
    h1{
      font-size: 70px !important;
      line-height: 109px !important;
    }
  }

  .title-que-es{
    padding-top: 0px;
  }
  
  
  .tienda-bg{
    background-image: url('/img/tienda-makemake/bg-tienda.svg');
    background-position: right -50%;
    background-size: 25%;
    background-repeat: no-repeat;
  }
}