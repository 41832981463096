/*=====================================================
    TIENDA-INSTITUCIONAL

    En este archivo se definen los estilos de la 
    pestaña para instituciones/contactanos
 ====================================================== */

@keyframes page-content-anim {
    0%{
        transform: translateY(15%);
        opacity: 0;
    }
    100%{
        transform: translateY(0%);
        opacity: 1;
    }
}

.plan-instituciones{
   /*  min-height: 90vh; */
    background: url("/img/background.png") center/cover fixed repeat;

    .instituciones-content{
        background: var(--surface-color);
        padding: 2em 1em 0 1em;
        min-height: 400px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
      
        $themes: (
          "clasico":(
            border: none
          ),
          "alto-contraste":(
            border: 1px solid var(--onsecondary-color)
          ),
        );
      
        @include ThemesProperties($themes);

        .wrapper-tabs{
            position: sticky;
            top: 55px;
            z-index: 900;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);

            .tab-responsive{
                color: var(--secondary-color);
                font-weight: 600;
                cursor: pointer;
            }
            
            .tab{
                width: 100%;
                padding: 10px 20px;
                justify-content: space-between;
                align-items: center;
                background: var(--background-color);
                border-radius: 0;
                text-align: left;
                
                font-size: .9em;
                font-weight: 600;
                cursor: pointer;
        
                $themes:(
                    "clasico":(
                        border: 1px solid #E3E3F9,
                        color: #6A6969
                    ),
                    "alto-contraste":(
                        border: 1px solid var(--onbackground-primary),
                        color: var(--onbackground-primary)
                    )
                );
                @include ThemesProperties($themes);
    
                &.current{
                    background: var(--secondary-color);
                    transition: all .2s;
                    display: flex;
    
                    $themes:(
                        "clasico":(
                            border: none,
                            color: var(--onsecondary-color)
                        ),
                        "alto-contraste":(
                            color: var(--onbackground-primary)
                        )
                    );
                    @include ThemesProperties($themes);
                }
            }
        }
        
        .instituciones-panel{
            /* min-height: 75vh; */
            min-height: 700px;
            border-radius: 30px 30px 0 0 !important;
            background-color: var(--secondary-color);
            border-radius: 10px 0 10px 0;

            $themes:(
                "alto-contraste":(
                    border: 1px solid var(--onbackground-primary)
                )
            );
            @include ThemesProperties($themes);

            .subsection{
                /* min-height: 70vh; */
                animation: page-content-anim .5s, backwards;
                
                &.instituciones-cita{
                    height: 100%;
                    min-height: 600px;
                    background-image: url('/img/tienda-makemake/cita-back.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom center;

                    .btn-cita{
                        width: 100%;
                        padding: 5px 10px;
                        margin-bottom: 30px;
                        color: var(--onprimary-inverse);
                        background-color: var(--primary-inverse);
                        border-radius: 5px;
                        font-family: var(--primary-font);
                        font-weight: 600;

                        &:hover{
                            transition: all .2s;
                            background-color: var(--onsecondary-color);
                            color: var(--onprimary-inverse);
                            border: 1px solid var(--onprimary-inverse);
                        }
                    }
                }
            }
        }

        @media screen and (min-width: 992px) {
            padding: 3em 3em 0 3em;
            
            .wrapper-tabs{
                display: block;
                position: relative;
                box-shadow: none;
                .tab{
                    border-radius: 10px 0 0 10px;
                    display: flex;
                    margin-bottom: 20px;
                }
            }

           /*  .instituciones-panel{    
                .subsection{
                    min-height: 95%;
                }
            } */
        }
    }

    .instituciones-logos{
        $themes: (
            "clasico":(
                background-color: #EEEEEE,
                border: none
            ),
            "alto-contraste":(
                background-color: var(--background-color),
                border: 1px solid var(--onsecondary-color)
            )
        );
        @include ThemesProperties($themes);
    }
}