/*=====================================================
                     club makemake
 ====================================================== */

html {
  scroll-behavior: smooth;
}

.container-club{
  overflow: hidden;
}

.titulo-principal {
  font-weight: bold;
  width: 100%;
}

.icon-libros {
  background-image: url("/img/club-makemake/icono-libros.png");
}

.icon-libro {
  background-image: url("/img/club-makemake/icon-libro.png");
}

.icon-libreta {
  background-image: url("/img/club-makemake/icon-libreta.png");
}

.elparaguaspodcast {
  background-image: url("/img/club-makemake/elParaguas.jpg");
}

.thomasysumundo {
  background-image: url("/img/club-makemake/thomasYsuMundo.jpg");
}

.vocesencantadas {
  background-image: url("/img/club-makemake/vocesEncantadas.jpg");
}

.labibliotecadecristy {
  background-image: url("/img/club-makemake/laBibliotecaDeCristy.jpg");
}

.micorazonencasa {
  background-image: url("/img/club-makemake/miCorazonEncasa.jpg");
}

.curuba{
  background-image: url("/img/club-makemake/curuba.jpg");
}

.vuelalibros{
  background-image: url("/img/club-makemake/vuelaLibros.png");
}

.conandres{
  background-image: url("/img/club-makemake/123andres.png");
}

.otrocuento{
  background-image: url("/img/club-makemake/otroCuento.png");
}

@mixin complementoBack {
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("/img/club-makemake/back-title-act2.png");
  background-repeat: repeat-x;
  background-position: top;
  background-size: cover;
  position: absolute;
  top: 0;
}

.titulo-principal-actualizacion-club {
  height: 150px;
  padding-top: 20px;
  padding-left: 60px;
  background: linear-gradient(to top left, rgba(102, 86, 212), rgba(118, 88, 218), rgba(128, 74, 204), rgba(102, 55, 156));
  position: relative;

  &::before {
    @include complementoBack;
    left: -100%;
  }

  &::after {
    @include complementoBack;
    right: -100%;
  }

  h1 {
    font-family: $secondary-font;
    color: var(--onviolet-color);
    line-height: 1.2;
  }
}

.parrafo-especial-act {
  font-size: 1.2em;
}

.icon-actualizacion-abajo {
  width: 170px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
  position: relative;
  cursor: pointer;

  @keyframes arriba {
    from {
      top: -20px;
    }

    to {
      top: 20px;
    }
  }

  img {
    width: 70px;
    position: absolute;
    animation: arriba ease 1s infinite;
    -webkit-animation: arriba ease 1s infinite;
    -moz-animation: arriba ease 1s infinite;
  }
}

.contenedor-inscribete{
  border-radius: 13px;
  $themes: ("clasico":(border: 5px solid #6656D4,
    ),
    "alto-contraste":(border: 5px solid var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

.text-boletin{
  font-size: 1em;
  line-height: 1.5;
  font-weight: 600;

  $themes: ("clasico":( color: #6656D4,
    ),
    "alto-contraste":(color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

.btn-inscribir {
  font-weight: 700;
  font-size: 1.2em;
  border-radius: 0.8rem;
  transition: 0.2s;
  color: var(--onsecondary-color);
  line-height: 1.2;
  @extend .noutline;

  $themes: ("clasico":(background:var(--scarlet-color)
  ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));

  @include ThemesProperties($themes);


  &:hover {
    color: var( --onviolet-inverse);
    transform: scale(1.01);
    @extend .noutline;
    $themes: ("clasico":(background-color: #6656D4,
      ),
      "alto-contraste":(border: 1px solid var(--onviolet-color),
        background-color: var(--violet-inverse),
      ));

    @include ThemesProperties($themes);
  }

  &:focus {
    @extend .noutline;
    background: #6656D4;
    color: var( --onviolet-inverse);
    $themes: ("clasico":(background-color: #6656D4,
      ),
      "alto-contraste":(border: 1px solid var(--onviolet-color),
        background-color: var(--violet-inverse),
      ));

    @include ThemesProperties($themes);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}

.siguenos-title {
  color: var(--scarlet-inverse);
  font-family: $secondary-font;
  font-size: 1.4em;
  font-weight: 700;

}

.title-redes{
  font-weight: 700;
  $themes: ("clasico":( color: #6656D4,
    ),
    "alto-contraste":(color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

.link-redes-act {
  font-style: italic;
  font-size: 1em;

  &:hover {
    $themes: ("clasico":(color: none),
      "alto-contraste":(color: var(--onviolet-color)),
    );

    @include ThemesProperties($themes);

  }
}

.haz-parte-title {
  font-family: $secondary-font;
  font-size: 2em;
  line-height: 0.5;
  font-weight: 700;

  $themes: ("clasico":( color: #6656D4,
    ),
    "alto-contraste":(color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

@mixin linea-club($color) {
  line-height: 1.5;
  border-bottom: 4px solid $color;
}

.linea-morada-club{
  @include linea-club(#6656D4)
}

.linea-violeta-club{
  @include linea-club(var(--violet-inverse))
}

.titulos-actualizacion-club {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 2.5em;
  line-height: 1.2em;
}

@mixin titulo-color($color) {
  $themes: ("clasico":(color: $color),
    "alto-contraste":(color: var(--scarlet-inverse)),
  );
  @include ThemesProperties($themes);
}
.titulo-morado-act{
  @include titulo-color(#6062A4);
}
.titulo-rojo-act{
  @include titulo-color(var(--scarlet-inverse));
}
.titulo-violeta-act{
  @include titulo-color(var(--violet-inverse));
}

@mixin numero-titles($color) {
  width: 70px !important;
  height: 70px !important;
  border-radius: 80%;
  border: solid 4px !important;
  text-align: center;
  display: inline-block ;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 45px;

  $themes: ("clasico":(border-color: $color,
    ),
    "alto-contraste":(border-color: var(--blue-inverse),
    ));

  @include ThemesProperties($themes);
}



@mixin fechastitle($color) {
  color: $color;
  font-family: $secondary-font;
  font-size: 1.3em;
  line-height: 1;
  font-weight: 500;
}
.titulo-plan-lecturas{
  @include fechastitle(var(--scarlet-inverse));
}

.titulo-make-vivo{
  @include fechastitle(var(--violet-inverse));
}

/*-------------------------------------------*\
  PLAN DE LECTURA
\*-------------------------------------------*/

.seccion-morado-claro {
  $themes:(
    "clasico":(
      background-color: #F4EFF9,
    ),
    "alto-contraste":(
      background-color: var(--surface-color),
      border: 1px solid,
      border-color: var(--onbackground-primary),
    ),
  );

  @include ThemesProperties($themes);

}
.parrafo-acompanamiento {
  font-size: 1em;
  font-weight: 700;
}

.contenedor-icon{
  width: 250px;
  height: 220px;
  position: relative;

  .texto-icon{
    border-radius: 10px;
    position: absolute;
    bottom: 10px;
    height:170px;

    $themes: ("clasico":( background: #6062A4,
      ),
      "alto-contraste":(background: var(--violet-inverse),
      ));

    @include ThemesProperties($themes);
  }
}

.fuente-bold-act{
  font-family: $secondary-font;
  font-weight: 500;
  text-align: center;
  color: var(--surface-color);
  font-size: 20px;
}

.icon-lecturas-act {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  z-index: 900;
  /*  position: absolute;
  bottom: 85px;
  left:20%; */
  background-color: var(--surface-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;

  $themes: ("clasico":(background-color: var(--surface-color),
      border: 5px solid #6062A4,
    ),
    "alto-contraste":(background-color: var(--onbackground-primary),
      border: 5px solid var(--violet-color),
    ),
  );

  @include ThemesProperties($themes);
}

.text-descargar{
  font-family: $secondary-font;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 700;
  $themes: ("clasico":( color: #6062A4,
    ),
    "alto-contraste":(color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

.btn-descargar-act {
  font-weight: 700;
  font-size: 1.2em;
  border-radius: 10px;
  text-align: center;
  transition: 0.2s;
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    color: var(--onviolet-color);
    transform: scale(1.01);

    $themes: ("clasico":(background-color: #6656D4,
      ),
      "alto-contraste":(background-color: var(--violet-color),
      ));

    @include ThemesProperties($themes);

    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    color: var(--onsecondary-color);

    $themes: ("clasico":(background-color: #6656D4,
      ),
      "alto-contraste":(background-color: var(--violet-color),
      ));

    @include ThemesProperties($themes);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}

.texto-especificacion-act {
  font-size: 1em;
  font-weight: 400;
  color: var(--onbackground-third);
  width: 100%;
  border: 3px solid var(--scarlet-inverse);
  border-radius: 15px;
  padding: 25px;
  margin: auto;
  text-align: left;

  span {
    color: var(--scarlet-inverse);
    font-weight: 700;
    text-decoration: underline;
  }
}

.titulo-planes {
  font-family: $secondary-font;
  font-size: 1.8em;
  line-height: 1.2;
  font-weight: 700;
}

.btn-planes {
  border-radius: 5px;
  font-weight: 700;
  font-size: 22px;
  transition: 0.2s;
  background: var(--scarlet-color);
  color: var(--onscarlet-color);
  line-height: 1.2;
  @extend .noutline;

  span {
    font-size: 1em;
  }

  $themes: (
    "clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    )
  );

@include ThemesProperties($themes);

&:hover {

  color: var( --onviolet-inverse);
  transform: scale(1.01);

  $themes: ("clasico":(background-color: #6656D4,
    ),
    "alto-contraste":(background-color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
  @extend .noutline;
}

&:focus {
  @extend .noutline;

  color: var( --onviolet-inverse);

  $themes: ("clasico":(background-color: #6656D4,
    ),
    "alto-contraste":(background-color: var(--violet-inverse),
    ));

  @include ThemesProperties($themes);
}

&:active {
  background: var(--primary-color) !important;
  transform: scale(0.95);
  @extend .noutline;

  &:focus {
    box-shadow: none !important;
  }
}

&:visited {
  @extend .noutline;
}
}

.div-planes {
  font-family: $secondary-font;
  border-radius: 15px;
  font-weight: 700;
  transition: 0.2s;
  background: var(--scarlet-color);
  color: var(--onscarlet-color);
  line-height: 1.2;
  position: relative;
  width: 100%; 

  span {
    font-size: 2em;
  }

  $themes: (
    "clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    )
  );

@include ThemesProperties($themes);

}

.boton-abre-modal {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: -8px;
  right: -13px;
  transition: 0.2s;

  &:hover{
    transform: scale(1.2);
  }

  $themes: ("clasico": (background: linear-gradient(to top left, rgba(102, 86, 212), rgba(118, 88, 218), rgba(128, 74, 204), rgba(102, 55, 156))),
    "alto-contraste": (background: var(--violet-color),
      border: 1px solid var(--onviolet-color)));

@include ThemesProperties($themes);
}

.icono-fa{
  color: var(--onviolet-color);
  font-size: 16px;
}

/*-------------------------------------------*\
  MAKEMAKE EN VIVO
\*-------------------------------------------*/


.titulo-rojo-act {
  $themes: ("clasico":(color: var(--scarlet-inverse)),
    "alto-contraste":(color: var(--scarlet-inverse)),
  );
  @include ThemesProperties($themes);
}

.parrafo-youtube{
  width:100%;
  font-weight: 700;
  font-size: 0.8em;
  color: var(--scarlet-inverse);
  line-height: 1.5;
}

.bnt-suscribete {
  font-weight: 700;
  font-size: 1.2em;
  transition: 0.2s;
  border-radius: 15px;
  padding: 7px 40px;
  background: var(--scarlet-color);
  color: var(--onviolet-color);
  line-height: 1,2;
  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    color: var(--onviolet-inverse);
    transform: scale(1.01);

    $themes: ("clasico":(background-color: #6666cc,
      ),
      "alto-contraste":(background-color: var(--violet-inverse),
      ));

    @include ThemesProperties($themes);

    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    color: var(--onviolet-inverse);

    $themes: ("clasico":(background-color: #6666cc,
      ),
      "alto-contraste":(background-color: var(--violet-inverse),
      ));

    @include ThemesProperties($themes);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}


.borde-amarillo{
  background-color: var(--primary-color);
  border: 5px solid var(--onprimary-color);
  border-bottom: 0px;
}

.contenedor-video{
  background-color: var(--surface-color);
  border: 5px solid var(--onprimary-color);
}


.contenedor-descarga{
  width: auto;
  position: relative;
}

.texto-programa{
  font-size: 1em;
  font-weight: 600;
  color: var(--onbackground-third);
  border: 5px solid var(--scarlet-inverse);
  border-radius: 25px;
  margin: auto;
  text-align: left;
}

.bnt-descargar-programa {
  font-weight: 700;
  font-size: 20px;
  transition: 0.2s;
  border-radius: 15px;
  position: relative;
  bottom: 20px;
  background: var(--scarlet-color);
  color: var(--onviolet-color);
  line-height: 1;
  cursor: pointer;

  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--secondary-inverse);
    color: var(--onsecondary-inverse);
    transform: scale(1.01);
    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    background: var(--secondary-inverse) !important;
    color: var(--onsecondary-inverse);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}


.make-youtube{
  width: 100%;
  height: 600px;
  background-image: url("/img/club-makemake/makemake-youtube.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/*-------------------------------------------*\
  COMUNIDAD LECTORA
\*-------------------------------------------*/

.texto-comunidad{
  color: var(--violet-inverse);
  font-family: $secondary-font;
  font-size: 1.2em;
  line-height: 1.2;
  font-weight: 700;
}

.user-comunidad2 {
  font-style: italic;
  font-weight: 600;
  font-size: 20px;

  &:hover {
    $themes: ("clasico":(color: none),
      "alto-contraste":(color: var(--onscarlet-color)));

    @include ThemesProperties($themes);
  }
}

.conoce-mas{
  font-weight: 700;
  font-size: 20px;
  line-height: 2;
  padding-bottom: 3px;
  font-kerning: none;
  border-bottom: 3px solid var(--violet-inverse);
  cursor: pointer;

  &:hover {
    $themes: ("clasico":(color: var(--violet-inverse), border-bottom: 3px solid var(--violet-inverse)) ,
      "alto-contraste":(color: var(--violet-inverse),  border-bottom: 3px solid var(--violet-inverse)));

    @include ThemesProperties($themes);
  }
}

.boton-abre-tool{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: 0.2s;
  cursor: pointer;

  &:hover{
    transform: scale(1.2);
  }

  $themes: ("clasico":(background: linear-gradient(to top left, rgba(102, 86, 212), rgba(118, 88, 218), rgba(128, 74, 204), rgba(102, 55, 156))),
    "alto-contraste":(background: var(--violet-color),
      border: 1px solid var(--onviolet-color)));

@include ThemesProperties($themes);
}

.comunidad-act {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: -1px 3px 5px rgba(0, 0, 0, 0.6);
  border: 1px solid var(--onprimary-color);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -1000px;
  overflow: hidden;
}

.degradado-comunidad{
  width: 100%;
  height: 30px;
  background: linear-gradient(to top left, rgba(102, 86, 212),rgba(118, 88, 218),rgba(128, 74, 204),rgba(102, 55, 156));

  $themes: ("clasico":(background: linear-gradient(to top left, rgba(102, 86, 212), rgba(118, 88, 218),rgba(128, 74, 204), rgba(102, 55, 156))),
    "alto-contraste":(background: var(--violet-inverse)));

  @include ThemesProperties($themes);
}

.imagen-comunidad{
  background-image: url("/img/club-makemake/nina-comunidad.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px;
}

/*-------------------------------------------*\
  SE PARTE DE LA COMUNIDAD
\*-------------------------------------------*/
.titulo-planes2 {
  font-family: $secondary-font;
  font-size: 1.3em;
  line-height: 1.5;
  font-weight: 700;
}

.btn-primary-act {
  font-weight: 700;
  font-size: 1em;
  transition: 0.2s;
  border-radius: 0.7rem;
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  line-height: 1.2;
  @extend .noutline;

  $themes: ("clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    ));

  @include ThemesProperties($themes);

  &:hover {
    color: var(--onviolet-color);
    transform: scale(1.01);

    $themes: ("clasico":(background-color: #6666cc),
      "alto-contraste":(background-color: var(--violet-color)));

    @include ThemesProperties($themes);

    @extend .noutline;
  }

  &:focus {
    @extend .noutline;
    color: var(--onviolet-color);

    $themes: ("clasico":(background-color: #6666cc),
      "alto-contraste":(background-color: var(--violet-color)));

    @include ThemesProperties($themes);
  }

  &:active {
    background: var(--primary-color) !important;
    transform: scale(0.95);
    @extend .noutline;

    &:focus {
      box-shadow: none !important;
    }
  }

  &:visited {
    @extend .noutline;
  }
}


.cuentanos-div {
  word-wrap: break-word;
  border-top: 4px solid var(--primary-inverse);
  border-width: 70%;
}

.correo-estilo {
  font-weight: 700;
  font-size: 1.2em;
  $themes: (
    "clasico":(
      color: #6062A4,
    ),
    "alto-contraste":(
      color: var(--violet-inverse),
    ),
  );
  @include ThemesProperties($themes);

  text-decoration: underline;
}

.background-contraste {

  $themes: ("clasico":(background-color: none,
    ),
    "alto-contraste":(background-color: var(--onbackground-primary),
    ),
  );
  @include ThemesProperties($themes);
}

.canvas-planes {
  width: auto !important;
  height: 170px !important
}

.contenedor-planes {
  width: auto !important;
}

.min-botton {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  //position: absolute;
  //top: -30px;
  //left: 150px;

  $themes: ("clasico": (background: linear-gradient(to top left, rgba(102, 86, 212), rgba(118, 88, 218), rgba(128, 74, 204), rgba(102, 55, 156))),
    "alto-contraste": (background: var(--violet-color),
      border: 1px solid var(--onviolet-color)));

  @include ThemesProperties($themes);
}

.titulo-plan {
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  transition: 0.2s;
  font-family: $secondary-font;
  background: var(--scarlet-color);
  color: var(--onscarlet-color);
  line-height: 1.2;
  //position: absolute;
  //top: -50px;
  //right: 150px;
  @extend .noutline;

  span {
    font-family: $secondary-font;
    font-size: 32px;
  }

  $themes: (
    "clasico":(border: none,
    ),
    "alto-contraste":(border: 1px solid var(--onviolet-color),
    )
  );

@include ThemesProperties($themes);

}

.titulo-comunidad{
  color: var(--violet-inverse) !important;
  font-family: $secondary-font;
  font-size: 2em !important;
  line-height: 1.2;
  font-weight: 700;
}

.comunidad-descripcion {
  color: var(--onbackground-third) !important;
  font-size: 1em;
  font-weight: 600;
  word-wrap: break-word;
}


@media (min-width: 320px) {

  .club {
    background-size: cover;
  }

  .fechas-title {
    font-size: 1.2em;
  }

  .text-descargar {
    font-size: 1em;
  }

  .contenedor-icon {
    width: 210px;
    height: 215px;
  }
  .titulo-principal-club {
    height: 170px;
    padding-top: 60px;
    background-image: url("/img/club-makemake/back-title2.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    background-color: #6666cc00;

    h1 {
      font-size: 24px;
    }
  }

}


@media (min-width: 576px) {
  .titulo-principal-actualizacion-club {
    height: 250px;
    padding-top: 20px;
    padding-left: 120px;
    background-image: url("/img/club-makemake/back-title-act.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    h1 {
      font-size: 48px;
    }
  }

  .fechas-title {
    font-size: 1.5em;
  }

  .contenedor-icon {
    width: 40%;
    height: 220px;
  }

  .bnt-suscribete {
    line-height: 2;
  }

  .parrafo-youtube {
    width: 90%;
  }

  .canvas-planes {
    height: 300px !important
  }

  .contenedor-tooltip {
    width: 400px;
  }

  .tooltipclub {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .cuentanos-div {
    border-top: none;
    border-left: 4px solid var(--primary-color);

  }

  .contenedor-icon {
    width: 30%;
    height: 220px;
  }

  .canvas-planes {
    height: 400px !important
  }
}

@media (min-width: 992px) {
  .titulo-principal-actualizacion-club {
    height: 350px;
    padding-top: 20px;
    padding-left: 120px;

    h1 {
      font-size: 64px;
    }
  }

  .haz-parte-title {
    font-size: 2.5em;
  }

  .fechas-title {
    font-size: 1.5em;
  }

  .text-descargar {
    font-size: 1.2em;
  }

  .bnt-suscribete {
    line-height: 1.2;
  }

  .contenedor-icon {
    width: 40%;
    height: 220px;
  }

  .canvas-planes {
    height: 500px !important
  }

  .contenedor-planes {
    width: 820px !important;
  }
  .titulo-principal-club {
    height: 320px;
    padding-top: 100px;

    h1 {
      font-size: 56px;
    }
  }

  .titulos-club,
  .titulo-principal {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .titulo-principal-actualizacion-club {
    height: 400px;
    padding-top: 30px;
    padding-left: 120px;

    h1 {
      font-size: 70px;
    }
  }

  .comunidad-act {
    width: 180px;
    height: 180px;
  }

  .bnt-suscribete {
    line-height: 2;
  }

  .contenedor-icon {
    width: 30%;
    height: 220px;
  }

  .canvas-planes {
    height: 600px !important
  }

  .contenedor-planes {
    width: 990px !important;
  }
  .titulo-principal-club {
    height: 400px;
    padding-top: 150px;

    h1 {
      font-size: 56px;
    }
  }

  .titulos-club,
  .titulo-principal {
    width: 90%;
  }
}

@media (min-width: 1440px) {
  .canvas-planes {
    height: 700px !important
  }

  .contenedor-planes {
    width: 1150px !important;
  }
}