@use "sass:map";
/*=====================================================
    QUE ES?
    En este archivo se definen los estilos 
    generales de la sección del ¿qué es?
 ====================================================== */

.que-es {

  p {
    font-size: 0.9em;
  }

  .btn {
    font-family: var(--secondary-font);
    font-weight: 600;
    padding: 12px 30px;
    font-size: pxToEm(18);
  }

  // la clase se usa cuando el botón amarillo esta sobre un fondo de color morado, se cambia el hover a color rojo para que no se vea rara la transición de estado.
  .primary-onsecondary-button {
    @extend .btn-primary;

    &:hover {
      background-color: var(--red-color);
      color: var(--onred-color);
    }
  }

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .main-plans-shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("/img/que-es/home/plans/books_shape.png");
    background-position: 0 0;
    background-repeat: repeat;
    background-size: contain;
    z-index: -1;
    opacity: 0.1;
  }

  .main-plan-card {
    position: relative;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    $themes: (
      "clasico": (
        background-color: #f4f4ff
      ),
      "alto-contraste": (
        background-color: var(--background-color)
      )
    );

    @include ThemesProperties($themes);

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .image-mobile{
      width: 100%;
      height: 100px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
    }

    .image {
      width: 93%;
      height: auto;
    }

    .btn {
      font-size: pxToEm(14);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 50px;
    }

    .bordered-button {
      border: solid 2px var(--onbackground-primary);
      color: var(--onbackground-primary);
      background-color: inherit;
      transition:
        border 0.3s,
        color 0.3s;

      &:hover {
        border: solid 2px var(--secondary-inverse);
        color: var(--secondary-inverse);
      }
    }

    .features {
      &.visible {
        display: block;
      }

      &.hidden {
        display: none;
      }

      .feature {
        font-size: pxToEm(13);
      }
    }
  }

  .alternative-plan-card {
    position: relative;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    background-color: var(--surface-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .title {
      $themes: (
        "clasico": (
          color: #262626
        ),
        "alto-contraste": (
          color: var(--onbackground-primary)
        )
      );
      @include ThemesProperties($themes);
    }

    .image {
      width: 100%;
      height: auto;
    }

    .btn {
      font-size: pxToEm(14);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 50px;
    }

    .bordered-button {
      border: solid 2px var(--onbackground-primary);
      color: var(--onbackground-primary);
      background-color: inherit;
      transition:
        border 0.3s,
        color 0.3s;

      &:hover {
        border: solid 2px var(--secondary-inverse);
        color: var(--secondary-inverse);
      }
    }

    .features {
      &.visible {
        display: block;
      }

      &.hidden {
        display: none;
      }

      .feature {
        font-size: pxToEm(13);
      }
    }
  }

  .publisher-title {
    font-size: pxToEm(18);
    font-weight: 600;
  }

  .community-service-card {
    width: 220px;
    height: 300px;
    overflow: hidden;
    background-color: var(--surface-color);
    border-radius: 0.5rem;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);

    .title {
      font-size: pxToEm(14);
      font-weight: 700;
      font-family: var(--secondary-font);
      color: var(--onbackground-primary);
      height: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .image-container {
      position: relative;
      height: 180px;

      .image {
        position: relative;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .shape {
        position: absolute;
        top: 25%;
        left: 7%;
        width: 100%;
        height: 100%;
        transform: skewX(-25deg);
      }
    }
  }

  .accesible-image {
    $themes: (
      "alto-contraste": (
        background-color: var(--onbackground-primary)
      )
    );

    @include ThemesProperties($themes);
  }

  .newsletter-cta {
    h2{
      font-size: 1.2em;
    }
    $themes: (
      "clasico": (
        background-color: #e3e3f9
      ),
      "alto-contraste": (
        background-color: var(--background-color)
      )
    );

    @include ThemesProperties($themes);
    .shape {
     /*  position: absolute; */
      background-image: url("/img/que-es/home/newsletter/newsletter-speaker.png");
      background-position: 0%;
      background-size: contain;
      background-repeat: no-repeat;
      width: 200px;
      height: 200px;
      margin: auto;

      @media (min-width: 992px) {
        width: 465px;
        height: 332px;
      }

      @media (min-width: 1200px) {
        width: 555px;
        background-size: cover;
        background-position: 5% -10%;
      }

      @media (min-width: 1500px) {
        width: 670px;
        height: 266px;
        background-size: 75%;
        background-position: 5% 12%;
      }
    }
  }

  .awards{
    a{
      transition: all 0.2s;
      &.active {
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  .books-preview {
    .preview-button-option {
      border-radius: 999999px;
      transition:
        color 0.3s,
        background-color 0.3s,
        transform 0.3s;
      $themes: (
        "clasico": (
          background-color: #eaeaff,
          color: var(--onbackground-primary)
        ),
        "alto-contraste": (
          background-color: var(--background-color),
          color: var(--onbackground-primary),
          border: solid 1px var(--secondary-inverse)
        )
      );

      @include ThemesProperties($themes);

      &.selected {
        $themes: (
          "clasico": (
            background-color: var(--secondary-inverse),
            color: var(--onsecondary-inverse)
          ),
          "alto-contraste": (
            background-color: var(--secondary-inverse),
            color: var(--onsecondary-inverse),
            border: solid 1px var(--secondary-inverse)
          )
        );

        @include ThemesProperties($themes);
        background-color: var(--secondary-inverse);
        color: var(--onsecondary-inverse);
      }

      &:hover:not(.selected) {
        transform: translateY(-5%);
      }
    }
  }

  .book-swiper {
    .swiper-slide {
      width: 300px;
      height: auto;

      img {
        box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.346);
      }
    }
  }

  .more-plans {
    background-image: url("/img/que-es/home/more-plans/letters-shape.png");
    background-position: 0% 25%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hero {
    $themes: (
      "clasico": (
        color: #262626
      ),
      "alto-contraste": (
        color: var(--onbackground-primary)
      )
    );
    @include ThemesProperties($themes);

    .btn-secondary {
      transition:
        color 0.3s,
        background-color 0.3s;
      &:hover {
        background-color: var(--red-color);
        color: var(--onred-color);
      }
    }

    img {
      max-width: 100%;
    }
    @media (max-width: 991px) {
      img{
        max-width: 200px;
      }
    }
  }

  @media (max-width: 991px) {
    h1{
      font-weight: 600;
      font-size: 1.3em;
    }

  }

  
}
