/*=====================================================
                    DESCARGAS
 ====================================================== */

.btn-eliminar {
  font-weight: 700;
  $themes:(
    "clasico":(
      background-color: #e3e3e3,
      color: var(--onbackground-primary)
    ),
    "alto-contraste":(
      background-color: var(--surface-color),
      color: var(--onbackground-primary),
      border:solid 2px var(--onbackground-primary)
    )
  );

  @include ThemesProperties($themes);

}

.texto-sin-descargas {
  font-weight: 700;
}

.libro-descargado {
  border-top: 1px solid var(--onbackground-primary);
  color: var(--onbackground-primary) !important;

  .titulo {
    font-size: 1.3em;
  }

  .autor {
    font-size: 1.15em;
  }

  .tiempo-disponible {
    border-top: 1px solid var(--onbackground-primary);
    margin-top: 0.75rem;
    display: inline-block;
    span{
      color: var(--onbackground-third) !important;
    }
  }

  .cover {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.45);
  }
}

.descargados-title {
  border-bottom: none !important;
}

%message-download {
  font-weight: 600;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  background-size: 40px auto !important;
  padding-left: 60px;
}

.download-text {
  @extend %message-download;
  max-width: 500px;
  background: url("/img/icons/download-icon.svg");
}

.download-alert {
  @extend %message-download;
  background: url("/img/icons/alerta.svg");
}

.total-libros {
  font-size: 1.25em;

  .number {
    font-size: 2em;
  }
}

.sin-conexion-container {
  background: url("/img/sinconexion-screen.png") center top/cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2000;

  p {
    font-size: 1.1em;
  }
}

.sinconexion-message {
  border-radius: 8px;
  color: var(--onsecondary-color);
  max-width: 500px;
  text-align: center;

  $themes:(
    "clasico":(
      background: rgba(#333333, 0.95),
    ),
    "alto-contraste":(
      background: var(--background-color)
    ),
  );

  @include ThemesProperties($themes);

  img {
    max-width: 250px;
  }

  h2 {
    color: var(--primary-color);
  }
}

.limite-descarga {
  img {
    max-width: 400px;
    margin-bottom: 1rem;
  }
}

.descarga-instrucciones-title {
  h1 {
    text-transform: none;
    font-size: 1.8em;
  }

  p {
    font-size: 0.8em;
    max-width: 350px;
    background: url("/img/icons/chrome-icon.svg");
    background-position: right center !important;
    padding-right: 50px;
    background-repeat: no-repeat !important;
    background-size: 40px auto !important;
  }

  .safari {
    background: url("/img/icons/safari.svg");
  }
}

.descarga-instrucciones-pasos {
  padding: 1em 0;
  text-align: center;
  line-height: 1.3em;

  h2 {
    border-bottom: 0;
  }

  p {
    font-family: $font-family-sans-serif;
    color: var(--onbackground-primary);
    font-size: 0.9em;
  }
}

.descarga-instruccion-logo {
  img {
    max-width: 100px;
  }
}

.descarga-image {
  margin-bottom: 1.3em;
}

@media only screen and (min-width: 576px) {
  .descarga-instrucciones-title {
    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1em;
    }
  }

  .texto-sin-descargas {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 576px) {
  .sin-conexion-container {
    p {
      font-size: 0.8em;
    }
  }
}

@media only screen and (min-width: 992px) {
  .descarga-image {
    min-height: 175px;
  }

  .descarga-instrucciones-title {
    h1 {
      background: url("/img/icons/download-button-purple.svg");
      background-position: left 70%;
      display: inline-block;
      line-height: 1.3em;
      padding-left: 50px;
      background-repeat: no-repeat;
      background-size: 40px auto;
    }
  }

  .descarga-instrucciones-pasos {
    padding: 2em 0;

    &:not(:last-child) {
      border-right: 1px solid #969191;
    }
  }
}
