/*=====================================================
    ACCORDION
    En este archivo se encuentran los estilos
    del acordeón.
 ====================================================== */

.accordion{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
    box-shadow: 0 0 10px 0px rgba(0,0,0,.5);
    background-color: var(--surface-color);
    color: var(--onsurface-color);

    > .item{
        padding: 0;
        margin: 0;
        border-bottom: solid 1px var(--onbackground-primary);
        &.expanded{

        }
        &.collapsed{
            
        }
        &.expanded >.itemButton > .icon{
            transform: rotate(180deg);
        }
        &.collapsed>.itemButton > .icon{
            transform: rotate(0deg);
        }
        &.expanded >.content {
            display: flex;
            visibility: visible;
            transform: scaleY(100%);
            opacity: 1;
            
        }
        &.collapsed>.content {
            display: none;
            visibility: hidden;
            transform: scaleY(0);
            opacity: 0;
        }
        
        &.expanded >.itemButton {
            color: var(--secondary-inverse);    
        }
        >.itemButton{
            padding: .5rem;
            padding-left: 1rem;
            margin: 0;
            font-weight: bold;
            background-color: inherit;
            color: var(--onbackground-primary);
            transition: color .3s;
            width: 100%;
            box-shadow: 0 0 10px 0px rgba(0,0,0,.25);
            display: flex;
            /* grid-template-columns: 1fr min-content; */
            align-items: center;

            &:hover{
                color: var(--secondary-inverse);  
            }
            span{
                text-align: left;
                font-size: .9em;
                width: 90%;
            }
            >.icon{
                font-size: 35px;
                width: 10%;
                transition: transform .3s;
                text-align: end;
            }
        }
        >.content{
            padding: 1em;
            margin: 0;
            overflow: auto;
            background-color: var(--background-color);
            transition: all .3s;
            font-size: .9em;
        }
    }
}

@media screen and (min-width: 992px){
    .accordion{
        > .item{
            >.itemButton{
                span{
                    width: 80%;
                }
                >.icon{
                    width: 20%;
                }
            }
        }
    }
}