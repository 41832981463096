.staticBagde{
  width:100%;
  background-color: var(--primary-inverse);
 
  .badge{
    width: 100%;
    height: 100px;
    display: block;
    padding: 20px 0; 
    background-image: url('/img/crossmedia-responsive.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
  }

  @media only screen and (min-width: 768px) {
    .badge{
      background-size: 400px;
    }
  }

  @media only screen and (min-width: 1350px) {
    position: fixed;
    width: auto;
    background-color: rgba(240, 248, 255, 0);
    z-index: 6000;
    
    .badge{
      width: 150px;
      height: 150px;
      background-size: contain;
      background-image: url('/img/crossmedia-winner.png');
      transition: all .5s;

      &:hover{
        transform: scale(0.8);
      }
    }
  }
}

