/*===========================================================================================
 Ficha libro
 Define los estilos para la ficha del libro.
 ============================================================================================ */

/*-------------------------------------------*\
  Contenedor principal
\*-------------------------------------------*/
.contenedor-ficha {
  background-color: var(--surface-color);
  border-right: 1px solid var(--onbackground-secondary);
  border-left: 1px solid var(--onbackground-secondary);
  border-bottom: 1px solid var(--onbackground-secondary);
}

.tituloFicha {
  font-family: var(--secondary-font);
  text-transform: none !important;
  color: var(--onbackground-third) !important;
  font-weight: 700 !important;
  line-height: 1.3em !important;
  font-size: 2.05em;
}

/*-------------------------------------------*\
  Portada
\*-------------------------------------------*/
.ficha-libro-cover-wrapper {
  position: relative;
  max-width: 257px;
  min-height: 400px;
  background-color: var(--surface-color);
  -webkit-box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.4);
  box-shadow: 1px 1px 8px 0px rgba(0,0,0,0.4);

  @media only screen and (max-width:320px) {
    max-width: 200px;
    min-height: 300px;
  }
}

/*-------------------------------------------*\
  Info básica y más detalles
\*-------------------------------------------*/
.nav-ficha {
  border-bottom: 1px solid var(--onbackground-secondary);

  .nav-button-ficha {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid var(--onbackground-fourth);
    border-left: 1px solid var(--onbackground-fourth);
    border-right: 1px solid var(--onbackground-fourth);
    border-bottom: none;
    background-color: var(--background-color);
    font-family: var(--secondary-font);
    font-weight: 500;
    line-height: 25px;
    color: var(--onbackground-primary);
    transition: 0.2s;
  }

  .nav-button-ficha-active {
    background-color: var(--secondary-inverse);
    color: var(--onsecondary-inverse);
    border: none !important;
    font-weight: 700;

  }
}

.btn-favorito {
  font-family: var(--secondary-font);
  font-weight: 700;
  font-size: 0.9em;
  color: var(--onbackground-primary);
  transition: 0.2s;

  &:hover {
    font-size: 1em;
  }
}

.fichalibro-data--link {
  font-size: 1.2em;
  color: var(--onbackground-primary);
  font-weight: 600;
  padding-bottom: 5px;
  background: transparent linear-gradient(to right, var(--primary-inverse) -10px, var(--primary-inverse) 100%) -10px 100%/100% 4px repeat-x;

  &:hover {
    color: var(--blue-inverse);
  }
}

.fichalibro-data--name {
  font-weight: 600;
  font-size: 0.95em;
  color: var(--onbackground-secondary);
  margin-top: 3px;
}

.ficha-icon-tiempo {
  color: var(--secondary-inverse);
  font-size: 1.3em;
}

.ficha-actividad {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--surface-color);
  border: 1px solid var(--onbackground-fourth);
  border-radius: 10px;
  padding: 10px;
  transition: 0.2s;

  img {
    width: 30px;
    height: 43px;
  }

  p {
    font-size: 0.8em;
    font-weight: 700;
    font-family: var(--primary-font);
    color: var(--secondary-inverse);
  }

  &:hover {
    transform: scale(1.05);
    border: 1px solid var(--onbackground-fourth);
    color: var(--onbackground-secondary);
  }

}

/*-------------------------------------------*\
  Botones nivel, materia y género
\*-------------------------------------------*/

.fichalibro-data--features {
  >a,
  .ficha-item {
    border: 1px solid var(--onbackground-fourth);
    border-radius: 8px;
    text-align: center;
    font-size: 0.8em;
    font-family: var(--primary-font);
    font-weight: 700;

    p {
      line-height: 1.2;
      color: var(--onbackground-secondary);

      &:nth-child(1) {
        color: var(--onbackground-secondary);
      }

      &:nth-child(2) {
        color: var(--secondary-inverse);
      }
    }

    span {
      color: var(--secondary-inverse);
    }
  }
}

.data-ficha-features {
  display: block;
  height: 60px;
  border: 1px solid var(--onbackground-fourth);
  border-radius: 8px;
  text-align: center;
  font-size: 0.8em;
  font-family: var(--primary-font);
  font-weight: 700;
  transition: 0.2s;
  color: var(--onbackground-secondary);

  p {
    line-height: 1.2;

    &:nth-child(2) {
      color: var(--secondary-inverse);
    }
  }

  span {
    color: var(--secondary-inverse);
  }

  &:hover {
    transform: scale(1.05);
    border: 1px solid var(--onbackground-fourth);
    color: var(--onbackground-secondary);
  }

  &:active {
    border: 1px solid var(--onbackground-fourth);
    color: var(--onbackground-secondary);
  }

}

.ficha-libro-nivel {
  background-size: auto 75px;
  background-position: -26px center;
  background-repeat: no-repeat;
  padding-left: 60px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ficha-libro-materia {
  background-size: auto 40px;
  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: 50px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fichalibro-data--genero {
  background-color: var(--secondary-color);
  border: none !important;

  $themes: (
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--secondary-inverse)
    )
  );
  @include ThemesProperties($themes);

  p {
    color: var(--onsecondary-color) !important;
  }

  img {
    width: 30px;
  }
}

a[data-materia="Artes y manualidades"] {
  background-image: url("/img/icons/artes.png");
}

a[data-materia="Ciencias"] {
  background-image: url("/img/icons/ciencias.png");
}

a[data-materia="Historia y ensayo"] {
  background-image: url("/img/icons/historia.png");
}

a[data-materia="Canción y poesía"] {
  background-image: url("/img/icons/no-ficcion.png");
}

a[data-materia="Letras y números"] {
  background-image: url("/img/icons/matematicas.png");
}

a[data-materia="Narrativa"] {
  background-image: url("/img/icons/literatura.png");
}

a[data-materia="referencia"] {
  background-image: url("/img/icons/referencia.png");
}

a[data-nivel="Primeros lectores"] {
  background-image: url("/img/icons/nivel1.png");
}

a[data-nivel="Lectores intermedios"] {
  background-image: url("/img/icons/nivel2.png");
}

a[data-nivel="Lectores avanzados"] {
  background-image: url("/img/icons/nivel3.png");
}

/*-------------------------------------------*\
  Descripción
\*-------------------------------------------*/
.fichalibro-data--text {
  p {
    font-family: var(--primary-font);
    line-height: 1.55em;
    font-size: 1em;
    font-weight: 500;
    color: var(--onbackground-primary);
    text-align: justify;
  }
}

.fichalibro-data--info {
  font-family: var(--secondary-font);
  color: var(--onbackground-third);
  font-size: 1em;
  font-weight: 700;
}

/*-------------------------------------------*\
  Blog
\*-------------------------------------------*/
.ficha-link-blog {
  font-size: 1em;
  color: var(--onbackground-primary);
  font-weight: 600;
  padding-bottom: 5px;
  background: transparent linear-gradient(to right, var(--primary-inverse) -10px, var(--primary-inverse) 100%) -10px 100%/100% 4px repeat-x;

  &:hover {
    color: var(--blue-inverse);
  }
}

/*-------------------------------------------*\
  Botón de descarga
\*-------------------------------------------*/
@mixin ficha-btn-dowloads {
  border-radius: 6px;
  padding: 0px 40px 0px 13px;
  height: 46px;
  font-family: var(--primary-font);
  font-size: 0.9em;
  font-weight: 700;
}

.ficha-btn-descargado {
  width: 100% !important;
  @include ficha-btn-dowloads;
  border: 1px solid var(--secondary-inverse);
  color: var(--secondary-inverse);

  &:hover {
    color: var(--secondary-inverse);
    outline: none;
  }
}

.ficha-btn-descargar {
  width: 100% !important;
  @include ficha-btn-dowloads;
  background: var(--secondary-color);
  color: var(--onsecondary-color);

  $themes: ("clasico":(border-style: none,
    ),
    "alto-contraste":(border: 1px solid var(--onsecondary-color),
    ));
  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--blue-color);
    color: var(--onblue-color);
    transform: scale(1.01);
    outline: none;
    border: none;
    box-shadow: none;
  }
}

@mixin btns-download {
  background-size: 30px 30px;
  background-repeat: no-repeat;

}

.ficha-btn-downloaded-book {
  @include btns-download;
  background-position: 70%;
  background-image: url("/img/icons/btn-downloaded-icon.svg");
}

.ficha-download-book {
  @include btns-download;
  background-position: 70%;
  background-image: url("/img/icons/download.svg");
}

/*-------------------------------------------*\
  Botón de compartir
\*-------------------------------------------*/

.ficha-btn-compartir {
  width: 100% !important;
  background: var(--secondary-color);
  color: var(--onsecondary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;
  width: 188px;
  height: 46px;
  font-family: var(--primary-font);
  font-size: 0.9em;
  font-weight: 700;
  border-radius: 6px;

  $themes: (
    "clasico":(
      border-style: none,
    ),
    "alto-contraste":(
      border-style: solid,
      border-width: 1px,
      border-color: var(--onsecondary-color),
    )
  );
  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--onbackground-primary);
    color: var(--surface-color);
    transform: scale(1.01);
    outline: none;
    border: none;
    box-shadow: none;
  }
}

/*-------------------------------------------*\
  Botón de leer
\*-------------------------------------------*/
.ficha-btn-enviar {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  width: 147px;
  height: 46px;
  font-family: var(--primary-font);
  background: var(--primary-inverse);
  color: var(--onprimary-inverse);
  font-weight: 700;
  font-size: 1.2em;
  border-radius: 6px;

  $themes: (
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid var(--onsecondary-color),
    )
  );
  @include ThemesProperties($themes);

  &:hover {
    background-color: var(--secondary-color);
    color: var(--onsecondary-color);
    transform: scale(1.01);
    outline: none;
    border: none;
    box-shadow: none;
  }
}

/*-------------------------------------------*\
 Subtitulos
\*-------------------------------------------*/
.ficha-subtitle {
  width: 100%;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-family: var(--secondary-font);
    color: var(--secondary-inverse);
    font-weight: 700;
    font-size: 1em;
    border-bottom: none !important;
  }

  hr {
    display: block;
    width: 100%;
    margin-bottom: 9px;
    border: 1px solid;

    $themes: (
      "clasico":(
        border-color: #6666cc3f,
      ),
      "alto-contraste":(
        border-color:var(--secondary-inverse),
      )
    );
    @include ThemesProperties($themes);
  }
}

/*-------------------------------------------*\
  Botones de temas
\*-------------------------------------------*/
.ficha-tema {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 165px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s;
  margin: auto;

  $themes: (
    "alto-contraste":(
      border: 1px solid var(--primary-inverse),
    )
  );
  @include ThemesProperties($themes);

  span {
    text-align: center;
    font-family: var(--secondary-font);
    font-weight: 600;
    color: var(--onprimary-color);
  }

  &:hover {
    transform: scale(1.05);
  }
}

/*-------------------------------------------*\
  Botones de ver más
\*-------------------------------------------*/
.ficha-btn-ver-mas {
  font-family: var(--primary-font);
  color: var(--secondary-inverse);
  font-weight: 600;
  font-size: 0.9em;

  span {
    text-decoration: underline;
  }

  &:hover {
    color: var(--blue-inverse);
  }
}

/*-------------------------------------------*\
  Tarjeta libro recomendado
\*-------------------------------------------*/
.ficha-card-libro-skeleton {
  border-radius: 20px 0px 20px 0px !important;
}

.ficha-card-libro {
  height: 100%;
  max-width: 480px;
  margin: auto;
  transition: 0.2s;

  &:hover {
    transform: scale(1.05);
    border-radius: 20px 0px 20px 0px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.15);
  }

  .card-portada {
    border-radius: 20px 0px 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }

  .card-title {
    background-color: var(--secondary-inverse);

    h3 {
      color: var(--onsecondary-inverse);
      font-size: 0.9em;
      font-family: var(--secondary-font);

      line-height: 1.2em;
      display: -webkit-box;
      display: -moz-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2 !important;
      text-overflow: ellipsis;
    }
  }

  @mixin card-valores {
    font-size: 0.8em !important;
    font-family: var(--secondary-font) !important;
    line-height: 1.2em;
    display: block !important;
    overflow: hidden;
    -webkit-line-clamp: 1 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card-autor {
    @include card-valores;
    font-weight: 700 !important;
    color: var(--onprimary-color) !important;
  }

  .card-editorial {
    @include card-valores;
    color: var(--onbackground-third) !important;
  }

  .card-materia {
    @include card-valores;
    text-transform: uppercase !important;
    color: var(--secondary-inverse) !important;
    letter-spacing: 3px;
  }

  .card-descipcion {
    background: var(--background-color);
    border-radius: 0px 0px 20px 0px;

    $themes: (
      "alto-contraste":(
        border: 1px solid var(--onbackground-third)
      )
    );
    @include ThemesProperties($themes);

    p {
      font-size: 0.7em;
      color: var(--onbackground-third);
      font-weight: 500;
      font-family: var(--primary-font);
      width: 100%;
      display: -webkit-box;
      display: -moz-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


/*-------------------------------------------*\
  Botón de edad
\*-------------------------------------------*/
.ficha-btn-edad {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: var(--onsecondary-color);
  font-weight: 700;
  font-family: var(--primary-font);
  font-size: 18px;

  &:hover {
    $themes: (
      "clasico":(
        background-color: var(--secondary-color),
        color: var(--onsecondary-color)
      ),
      "alto-contraste":(
        background-color: var(--onsecondary-color),
        color: var(--secondary-color)
      )
    );
    @include ThemesProperties($themes);
  }
}

a[data-edad="Desde 3 años"] {
  $themes: (
    "clasico":(
      background: #8cc63f,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 5 años"] {

  $themes: (
    "clasico":(
      background: #c140ff,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
      ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 7 años"] {
  $themes: (
    "clasico":(
      background: #f2580c,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 9 años"] {
  $themes: (
    "clasico":(
      background: #b2ae1c,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 11 años"] {
  $themes: (
    "clasico":(
      background: #6680e3,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 13 años"] {
  $themes: (
    "clasico":(
      background: #f2b705,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

a[data-edad="Desde 15 años"] {
  $themes: (
    "clasico":(
      background: #cd3f61,
    ),
    "alto-contraste":(
      background-color: var(--secondary-color),
      border: 1px solid var(--onsecondary-color)
    ),
  );
  @include ThemesProperties($themes);
}

/*-------------------------------------------*\
  Icono tipo de libro
\*-------------------------------------------*/
.ficha-icon-tipoLibro {
  width: 36px;
  height: 35px;
}


/*-------------------------------------------*\
  botones de temas a los que pertenece el libro
\*-------------------------------------------*/
.ficha-btns-etiquetas {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 0.9em;

  $themes: (
    "clasico":(
      background-color: #DCDDED,
      color: #6A6969
    ),
    "alto-contraste":(
      background-color: var(--blue-inverse),
      color: var(--onblue-inverse)
    )
  );
  @include ThemesProperties($themes);

  &:hover {
    $themes: (
      "clasico":(
        background-color: var(--scarlet-color),
        color: var(--onscarlet-color)
      ),
      "alto-contraste":(
        background-color: var(--scarlet-color),
        color: var(--onscarlet-color),
        border: 1px solid var(--onscarlet-color)
      )
    );
    @include ThemesProperties($themes)
  }
}

/*------------------------------------------------------*\
  GR LEVEL, libro premiado, y libro asistido (accesible)
\*------------------------------------------------------*/
.ficha-btn-feature {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--surface-color);
  border: 1px solid var(--onbackground-secondary);
  border-radius: 10px;
  margin-bottom: 15px;

  font-family: var(--primary-font);
  font-weight: 700;
  font-size: 0.7em;
  color: var(--onbackground-primary);
  line-height: 20px;
  text-align: center;

  .gr-level {
    font-family: var(--secondary-font);
    font-weight: 700;
    font-size: 40px;
    color: var(--secondary-inverse);
    width: 50%;
  }

  .ficha-btn-premiado {
    width: 27px;
  }

  .ficha-btn-acc {
    font-size: 30px;
  }

  &:hover {
    transform: scale(1.05);
    border: 1px solid var(--onbackground-fourth);
    color: var(--onbackground-primary);
  }
}


/*------------------------------------------------------*\
  Ventana alerta limite
\*------------------------------------------------------*/
.alerta-limite-container {
  position: fixed;
  background: transparent;
}

.btn-cerrar-alerta {
  position: absolute;
  top: 10px;
  right: 10px;
  background: url("/img/close-button2.png") center/contain no-repeat;
  width: 40px;
  height: 40px;
  text-indent: -10000px;
  overflow: hidden;
}

/*------------------------------------------------------*\
  Ventana invitación a la lectura
\*------------------------------------------------------*/
.invitacion-lectura {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  align-items: center;
  top: 1rem;
  left: calc((100vw - 80vw)/2);
  width: 80vw;
  min-height: 50px;
  box-shadow: 0 .5px 10px 2px rgba(0, 0, 0, 0.4);
  z-index: 1001;
  background-color: rgba(#e4e4f8, .87);
  border-radius: .5rem;
  transition: transform .3s;

  $themes: (
    "alto-contraste":(
      background-color: var(--surface-color),
      border: solid 2px var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);

  &.visible {
    transform: translateY(0);
  }

  &.hidden {
    transform: translateY(-200%);
  }


  .close-button {
    position: absolute;
    top: -12px;
    right: -15px;
    width: 50px;
    height: 50px;
    justify-self: flex-end;
    align-self: flex-end;
    border-radius: 99999px;
    padding: .5rem;
    margin-right: .5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--red-inverse);
    color: var(--onred-inverse);
    transition: transform .3s;
    font-weight: bold;
    border: solid 3px var(--red-color);

    &:hover {
      transform: scale(1.1);
    }
  }

  .title {
    width: 100%;
    margin-bottom: .5rem;
    color: var(--secondary-inverse);
    // text-align: center;
    font-size: 1.5em;
    border-bottom: solid 4px var(--secondary-inverse);
  }

  .description {
    width: 100%;
    color: var(--onbackground-primary);
    font-size: 1.2em;
  }


  @media only screen and (max-width:800px) {
    .title {
      font-size: 1em;
    }

    .description {
      font-size: .8em;
    }
  }
}

/*botones de descarga*/
.ficha-botones-descarga {
  width: 100%;
}

@media screen and (min-width:414px) {
  .content-temas {
    height: 180px;
    overflow: hidden;
  }

  .ficha-tema {
    margin: 0 10px;
  }
}

@media screen and (min-width:768px) {

  .ficha-btn-compartir,
  .ficha-btn-enviar {
    width: auto !important;
  }

  .ficha-btn-downloaded-book,
  .ficha-download-book {
    background-position: 95% !important;
  }

  .ficha-botones-descarga {
    width: auto;
  }

  .ficha-btn-descargado {
    width: 188px;
  }

  .ficha-btn-descargar {
    width: 150px;
    text-align: left !important;
  }

  .ficha-actividad {
    width: 80%;
    margin-left: auto;

    p {width: 80px;}
  }
}

@media screen and (min-width: 992px) {
  .nav-ficha {
    .nav-button-ficha {
      &:hover {
        font-weight: 700;
      }
    }
  }

  .ficha-subtitle {
    display: flex;
    hr {width: 70%;}
  }
}