/*=====================================================
    ADVANCED-SEARCH
    Este archivo establece los estilos de la 
    búsqueda avanzada.
 ====================================================== */


@use "sass:map";

.advanced-search{
    position: fixed;
    top: 55px;
    height: 0;
    width: 100vw;
    height: calc(100vh - 55px);
    background-color: rgba(0,0,0,.7);
    z-index: var(--z-advanced-search);
    display: flex;
    transition: opacity .3s, visibility .3s;
    
    &.visible{
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    &.hidden{
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
    }
    &.visible > .menu .main-section{
        opacity: 1;
        transform: scale(1);
    }
    
    &.hidden > .menu .main-section{
        opacity: 0;
        transform: scale(1.5);
    }
        .close-button {
            display: none;
            border-radius: 9999px;
            padding: .5rem;
            margin: .5rem;
            color: var(--onsecondary-inverse);
            transition: color .3s, background-color .3s;
    
            &:hover {
    
                background-color: var(--onsecondary-inverse);
                color: var(--secondary-inverse);
            }
        }
    .main-section{
        opacity: 0;
        transform: scale(1.5);
        transition: opacity .3s, transform .3s;
    }

    > .menu{
        margin-right: 0;
        margin-left: 160px;
        background-color: transparent;
        padding: 0;
        max-width: 800px !important;
        overflow: auto;
        > .content{
            $themes:(
                "clasico":(
                    background-color: #DCDDEDE5
                ),
                "alto-contraste":(
                    background-color: var(--background-color)
                )
            );
            @include ThemesProperties($themes);
            font-size: .7em;
        }
    }


    .section-title{
        color: var(--secondary-inverse);
        font-weight: 700;
        font-family: var(--secondary-font);
    }
    .default-item-card{
        width: 100%;
        height: 100%;
        font-weight: 700;
        color:var(--onbackground-primary);
        transition: color .3s, transform .3s;
        &:hover{
            color: var(--secondary-inverse);
            transform: translateY(-.25rem);
            // font-weight: 800;
        }
        .lazy-image{
            justify-content: center;
            align-items: center;
        }
         .item-card-image{
                width: 60px!important;
                height: 60px!important;
        }
    }

    .reading-level-card{
        width: 100%;
        background-color: var(--background-color);
        border-radius: .5rem;
        color: var(--onbackground-primary);
        font-weight: 700;
        font-family: var(--secondary-font);
        box-shadow: -1px 2px 4px rgba(64, 64, 64, 0.25);
        border: solid 2px var(--background-color);
        position: relative;
        transition: color .3s, border, .3s, transform .3s;
        .name {

                width: 100%;
            }
            .lazy-image{
                width: fit-content!important;
                height: fit-content!important;
            }
        .item-card-image{
            width: 60px;
        }
        &:hover{
            color: var(--secondary-inverse);
            border-color: var(--secondary-inverse);
            transform: translateY(-.25rem);
        }
    }

    .subject-card{
        width: 100%;
        height: 100%;
        background-color: var(--background-color);
        border-radius: .5rem;
        color: var(--onbackground-primary);
        font-weight: 700;
        font-family: var(--secondary-font);
        box-shadow: -1px 2px 4px rgba(64, 64, 64, 0.25);
        border: solid 2px var(--background-color);
        position: relative;
        transition: color .3s, border, .3s, transform .3s;
        .name {
                width: 100%;
            }
            .lazy-image{
                width: 60px!important;
                height: 60px !important;
            }
        .item-card-image{
            width: 100% !important;
            height: auto !important;
            object-fit: contain;
        }
        &:hover{
            color: var(--secondary-inverse);
            border-color: var(--secondary-inverse);
            transform: translateY(-.25rem);
        }
    }

    .theme-card{
        position: relative;
        width: 100%;
        border-radius: .5rem;
        color: var(--onbackground-primary);
        font-weight: 700;
        font-family: var(--secondary-font);
        font-size: .9em;
        box-shadow: -1px 2px 4px rgba(64, 64, 64, 0.25);
        border: solid 2px var(--background-color);
        position: relative;
        transition: color .3s, border, .3s, transform .3s;
        width: 120px;
        height: 120px;
        overflow: hidden;
        z-index: 0;
        .name {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                text-align: center;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 1.1em;
                padding: .5rem;
                font-family: var(--secondary-font);
            }
        .shape{
            position: absolute;
            top: 0;
            left: 0;
            background-position: 0 0;
            object-fit: cover;
            width: 100%;
            height: 90%;
            z-index: 0;
        }

        &:hover{
            color: var(--secondary-inverse);
            border-color: var(--secondary-inverse);
            transform: translateY(-.25rem);
        }
    }

    .cloud-tag{
        background-color: var(--background-color);
        border-radius: 1.5rem;
        color: var(--onbackground-primary);
        font-weight: 700;
        font-family: var(--secondary-font);
        box-shadow: -1px 2px 4px rgba(64, 64, 64, 0.25);
        border: solid 2px var(--background-color);
        transition: color .3s, border, .3s, transform .3s;


        &:hover{
            color: var(--secondary-inverse);
            border-color: var(--secondary-inverse);
            transform: translateY(-.25rem);
            // font-weight: 800;
        }
    }

    .collection-card{
        width: 100%;
        border-radius: 99999px;
        border: solid 2px var(--background-color);
        transition: border-color .3s, transform .3s;
        font-weight: 700;
        padding-left: 1rem!important;
        padding-right: 1rem!important;
        letter-spacing: 0.15em;
        font-family: var(--secondary-font);
        text-transform: uppercase;
        font-size: .9em;

        &:hover{
            border-color: var(--secondary-inverse);
            transform: translateY(-.25rem);
        }
    }

    .search-footer{
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--secondary-color);
        a{
            color: var(--onsecondary-color);
            font-weight: 400;
        }
    }

     .search-header{
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;;
        background-color: var(--secondary-color);
        font-size: .8em!important;

        .search-header-button{
            border-radius: .5rem;
            padding: .5rem;
            margin: .5rem;
            font-weight: 700;
            transition: color .3s;
            border: solid 2px var(--onbackground-primary);
            $themes:(
                "alto-contraste":(
                    background-color:var(--background-color),
                    color:var(--onbackground-primary)
                ),
                "clasico":(
                    background-color: #fff,
                    color: #6A6969
                )
            );
            @include ThemesProperties($themes);

            &:hover{
                $themes:(
                    "alto-contraste":(
                        color:var(--onbackground-primary)
                    ),
                    "clasico":(
                        color: var(--secondary-inverse)
                    )
                );
                @include ThemesProperties($themes);
            }
        }
    }

     .search-input-wrapper{
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: row;
        border-radius: .5rem;
        border: solid 2px var(--onsecondary-color);
        padding: .5rem;
        justify-content: center;
        align-items: center;
        color: var(--onsecondary-color);
    
        $themes:(
            "alto-contraste":(
                background-color:var(--background-color)
            ),
            "clasico":(
                background-color: #262626CC
            )
        );
        @include ThemesProperties($themes);

        > .search-input{
            position: relative;
            border: none!important;
            outline: none !important;
            width: 100%;
            background-color: transparent;
            padding-left: .5rem;
            color: var(--onsecondary-color)!important;
            &::placeholder{
                color: var(--onsecondary-color);
            }
            &:focus, &:focus-visible{
                outline: none !important;
                border: none  !important;
            }
        }

        > .clear-button{
            width: 30px;
            height: 30px;
            border-radius: 999999px;
            font-weight: 800;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--onsecondary-inverse);
            color: var(--secondary-inverse);

        }
    }

    .matches-list{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--background-color);
        color: var(--onbackground-primary);
        max-height: 250px;
        overflow: auto;
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        z-index: 100;
        
         .match-item{
            padding: .5rem;
            overflow: hidden;
            width: 100%;
            text-align: start;
            font-weight: 700;
            color: var(--onbackground-primary);
            &:hover{
                color: var(--secondary-inverse);
            }
        }
    }

}

.language-selector{
   .lang{
    text-transform: uppercase!important;
    color: var(--onsecondary-color) !important;
    &.active{
        font-weight: 800;
    }
   }
}

.testing-border{
    // border:solid 2px #000;
}

@media screen and (max-width:map.get($grid-breakpoints, "md")) {
    .advanced-search {
        > .menu{
            margin-left: auto;
            margin-right: auto;
        }
        .search-footer{
            flex-wrap: wrap;
            position: relative;
        }
        .search-header{
            position: relative;
        }
    }
}

@media screen and (max-width:map.get($grid-breakpoints, "lg")) {
    .advanced-search {
        > .menu{
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (max-width:992px) {
    .advanced-search {
        top: 0;
        height: 100vh;
        .close-button{
            display: flex;
        }
        .search-header{
            padding-top: 0;
        }
    }
}

