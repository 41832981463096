  // Clase usada por el componente  LibrarySelect, el cual es un input select de autocompletado que muestra las biliotecas e institutos que tienen makemake.
  .library-select {
    .dark-input-wrapper{
      $themes:(
        "clasico":(
            border: solid 1.5px #fff,
            background-color: rgba(38, 38, 38, 0.8),
            color: #fff,
        ),
        "alto-contraste":(
            border: solid 1.5px var(--onbackground-primary),
            background-color: var(--background-color),
            color: var(--onbackground-primary),
        )
      );
      @include ThemesProperties($themes);
    }
    .select-icon{
      font-size: pxToEm(22);
      transition: transform .3s;
      cursor: pointer;
    }
    .search-icon{
        font-size: pxToEm(28);
        cursor: default;
    }
    input{
      background-color: transparent;
      border: none;
      outline: none;
      min-width: 30px!important;
    }
    input, input::placeholder{
      font-size: inherit;
      font-family: inherit;
      color: inherit;
    }

    .base-select{
      &.expanded .select-icon{
        transform: rotate(180deg);
      }
      &.collapsed .select-icon{
        transform: rotate(0deg);
      }
    }
    .list-wrapper {
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, .5);
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem;
      background-color: var(--background-color);
      position: relative !important;
    }
  
    .library-list {
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: var(--background-color);
  
      .library-item {
        padding: .5rem;
        color: var(--onbackground-primary);
        cursor: pointer;
  
        &.selected {
          background-color: var(--onsecondary-inverse);
          color: var(--onprimary-inv-var1);
        }
  
        &:not(.selected):hover {
          color: var(--secondary-inverse);
        }

        .institution-name{
            font-size: pxToEm(12);
            font-weight: 700;
            text-transform: uppercase;
        }
        .library-name{
            font-size: pxToEm(20);
            font-weight: 500;
          }
          .library-city-country{
            font-style: italic;
          }
          .library-address{
            font-size: pxToEm(12);
            font-style: italic;
        }

      }
      .label{
        margin: .5rem;
        font-weight: 800;
        text-transform: uppercase;
      }
      .separator{
        background-color: var(--onsecondary-inverse);
        width: 100%;
        height: 2px;
      }
    }
  }
