// COLORES ANTIGUOS
// $grey-primary: #4d4d4d;
// $grey-secondary : #989393;
// $grey-third : #333333;
// $grey-fourth : #b8b8b8;
// $grey-fifth : #f1f1f1;
// $primary-color: #FFD100;
// $secondary-color: #6666CC;
// $third-color: #6680e3;
// $red-color: #FF3548;
// $green-color: #8cc63f;
// $blue-color: #0096d8;


$secondary-font : 'Quicksand';

$grid-columns:      12;
$grid-gutter-width: 30px;
$font-size-base : 20;
// $font-family-sans-serif:
//   'Raleway', sans-serif,
//   // Emoji fonts
//   "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$btn-font-family: $secondary-font;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 526px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 640px,//540
  md: 740px,//720
  lg: 960px,
  xl: 1140px
);

:root{
  //COLORES
  --background-color: #f1f1f1; // se utiliza principalmente para los fondos y algunas veces como bordes.
  --surface-color: #ffffff; // se utiliza como superficie, está generalmente encima del background principal.
  --onbackground-primary: #4d4d4d; // se usa para textos y bordes
  --onbackground-secondary: #989393; // se usa para textos y bordes
  --onbackground-third: #333333; // se usa para textos
  --onbackground-fourth: #b8b8b8; // se utiliza para textos pero más que todo para bordes.

  --primary-color: #ffd100;
  --onprimary-color: #000;
  --primary-inverse: #ffd100;
  --onprimary-inverse: #000;
  --secondary-color: #6666cc;
  --onsecondary-color: #fff;
  --secondary-inverse: #6666cc;
  --onsecondary-inverse: #fff;
  --secondary-light-color: #e0e0f5;
  --onsecondary-light-color: #6666cc;
  --secondary-light-inverse: #e0e0f5;
  --onsecondary-light-inverse: #6666cc;
  --third-color: #6680e3;
  --onthird-color: #fff;
  --third-inverse: #6680e3;
  --onthird-inverse: #fff;
  --red-color: #ff3548;
  --onred-color: #fff;
  --red-inverse: #ff3548;
  --onred-inverse: #fff;
  --green-color: #8cc63f;
  --ongreen-color: #fff;
  --green-inverse: #8cc63f;
  --ongreen-inverse: #fff;
  --blue-color: #0096d8;
  --onblue-color: #fff;
  --blue-inverse: #0096d8;
  --onblue-inverse: #fff;
  --violet-color:#82559E;
  --onviolet-color:#fff;
  --violet-inverse:#82559E;
  --onviolet-inverse:#fff;
  --scarlet-color:#EB3D4D;
  --onscarlet-color:#fff;
  --scarlet-inverse:#EB3D4D;
  --onscarlet-inverse:#fff;
  --disabled-color: #666;
  --ondisabled-color: #fff;

  // FUENTES
  --primary-font:'Raleway';
  --secondary-font:'Quicksand'; 

  // ZINDEX
  --z-advanced-search:99999; 
}
