/*===========================================================================================
 Ficha-libro
 Aquí se encuentran algunos de los estilos compartidos por las diferentes fichas.
 ============================================================================================ */
 
.fichalibro-data {
  overflow: hidden;
  background: var(--surface-color);
  border: 1px solid var(--onbackground-fourth);
  border-top-width: 2px;
  position: relative;
}

.ficha-libro-cover {
  width: 100%;
  /*max-width: 257px;*/
}

.fichalibro-recommended {
  background-color: var(--background-color);

  h2 {
    text-align: center;
    border: none;
    text-transform: uppercase;
    font-size: 1.3em;
    line-height: 1.3;
  }
}

.data--tab {
  opacity: 0;
  height: 0;
  transition: opacity 0.5s cubic-bezier(0.18, 1.14, 0.5, 1.18);

  .container-tabs {
    display: none;
  }
}

.tab-active {
  opacity: 1;
  height: auto;

  .container-tabs {
    display: block;
  }
}

.data--tab::-webkit-scrollbar {
  width: 0.3em;
  background-color: #e3e3e3;
  border-radius: 5px;
}

.data--tab::-webkit-scrollbar-thumb {
  background-color: #666666;
  max-height: 100px;
  border-radius: 5px;
}

.book-download-progress {
  background-color: var(--secondary-color);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.85);
  color: var(--onsecondary-color);
  font-family: var(--primary-font);
  padding-left: 1rem;
  padding-right: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  .progress{
    $themes:(
      "clasico":(
        background-color: #4d4d98,
        border: none,
        outline: none,
      ),
      "alto-contraste":(
        background-color: var(--primary-color),
        border: 1px solid var(--onprimary-color),
      ),
    );

    @include ThemesProperties($themes);

    height: 0.9rem;
  }

  .progress-number {
    color: var(--primary-inverse);
  }

  .progress-bar {

    $themes:(
      "clasico":(
        background-color: #c2c2eb,
      ),
      "alto-contraste":(
        background-color: var(--onprimary-color),
      ),
    );

    @include ThemesProperties($themes);
  }

  .cancelar-descarga {
    cursor: pointer;
    font-size: 0.9em;
  }
}

%message-book-download {
  max-width: 720px;
  padding-left: 60px;
  background-position: center left;
}

.book-download-info {
  @extend %message-book-download;
  background: url("/img/icons/download.svg") left 80% / 60px auto no-repeat;
}
.book-download-alert {
  @extend %message-book-download;
  background: url("/img/icons/alerta.svg") 5px / 50px auto no-repeat;
}



.share-box {
  position: relative;
  cursor: pointer;
  width: 90%;

  .share-input {
    width: 100%;
    padding: 10px;
    padding-right: 40px;
    box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.4);
  }

  > .copy-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    transition: color 0.3s, background-color 0.3s;

    &:hover {
      color: var(--onprimary-color);
    }
  }
}

@media only screen and (max-width: 576px) {
  .contenedor-botones-descarga {
    right: 100px;

    .btn-download-book {
      background-size: 30px;
      padding-top: 31px;
      font-size: 0.9rem;
    }
  }
}

@media only screen and (min-width: 768px) {
  .book-download-progress {
    padding-left: 5rem;
    padding-right: 6rem;
  }
}