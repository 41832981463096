/*===========================================================================================
  Header
  En este archivo se definen los estilos del encabezado o header del sitio.
 ============================================================================================ */
header {
  box-shadow: 0 1px 15px #9f9f9f;
  z-index: 1000;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--background-color);
  color: var(--onbackground-primary);

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .slash2 {
    display: none;
  }

  .correo {
    display: block;
  }
}

.search-input {
  font-family: $secondary-font;
  height: 33px;
  border-radius: 10px;
  outline: none !important;
  border: 2px solid var(--secondary-color) !important;
  font-size: 1.08em;
  font-weight: 500;
  color: var(--onbackground-primary) !important;

  +i {
    color: var(--secondary-color);
  }

  &:focus {
    border-color: none !important;
    box-shadow: none !important;
    transition: none !important;
  }
}

.btn-menu {
  font-size: 1.2em;
  font-weight: 600;
  border: none;
  outline: none;
  background: none;
  color: inherit;
}

.search-icon {
  cursor: pointer;
}

.mm-brand {
  max-height: 35px;
  max-width: 150px;
}

.altura-header{
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  $themes:(
    "alto-contraste":(
      border-bottom: 1px solid var(--onbackground-primary)
    )
  );
  @include ThemesProperties($themes);
}

.width-explore,  
.width-options2,  
.width-options{
  width: auto;
  .btn-header-amarillo{
    width: auto;
  }
}

.search-menu {
  .btn {
    color: inherit !important;
  }

  flex-direction: column-reverse;
}

.skip-link {
  height: 0;
  display: block;
  color: var(--surface-color);
  &:focus{
    cursor: pointer;
    font-family: var(--primary-font);
    background: var(--secondary-color);
    height: auto;
    text-align: center;
    color: var(--onsecondary-color);
    $themes:(
        "alto-contraste":(
            border: 1px solid var(--onsecondary-color)
        )
    );
    @include ThemesProperties($themes)
  }
}

.btn-responsive{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: var(--surface-color);
  font-family: var(--primary-font);
  color: var(--onbackground-primary);
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 10px;
  width: 100%;

  i{
    font-size: 1.2em;
  }

  $themes:(
    "alto-contraste":(
      border: 1px solid var(--secondary-inverse)
    )
  );
  @include ThemesProperties($themes);

  .icon-buzon{
    $themes:(
      "alto-contraste":(
        background-image: url('/img/icons/campana-contraste.svg')
      )
    );
    @include ThemesProperties($themes); 
  }

  &:hover,
  &:active{
    transition: 0.2s ease-in;
    transform: scale(1.03);
    color: var(--secondary-inverse);

    .i-favoritos{
      $themes:(
        "clasico":(
          background-image: url('/img/icons/favorito-active.svg')
        )
      );
      @include ThemesProperties($themes);
    }

    .i-descargas{
      $themes:(
        "clasico":(
          background-image: url('/img/icons/descarga-active.svg')
        )
      );
      @include ThemesProperties($themes);
    }

    .i-panel{
      $themes:(
        "clasico":(
          background-image: url('/img/icons/panel-active.svg')
        )
      );
      @include ThemesProperties($themes);
    }
  }

  .icon-cuenta{
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
  }

  .i-favoritos{
    $themes:(
      "clasico":(
        background-image: url('/img/icons/favorito.svg')
      ),
      "alto-contraste":(
        background-image: url('/img/icons/favorito-contraste.svg')
      )
    );
    @include ThemesProperties($themes);
  }

  .i-descargas{
    $themes:(
      "clasico":(
        background-image: url('/img/icons/descarga.svg')
      ),
      "alto-contraste":(
        background-image: url('/img/icons/descarga-contraste.svg')
      )
    );
    @include ThemesProperties($themes);
  }

  .i-panel{
    $themes:(
      "clasico":(
        background-image: url('/img/icons/panel.svg')
      ),
      "alto-contraste":(
        background-image: url('/img/icons/panel-contraste.svg')
      )
    );
    @include ThemesProperties($themes);
  }
}

.icono-home{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}



.btn-explorar-activo{
  border-bottom: 4px solid !important;
}

.btn-explorar{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--primary-font);
  font-weight: 600;
  width: 100%;
  font-size: 1em;
  width: 130px;
  margin-right: 25px;
  height: 100%;

  &:hover{
    border-bottom: 4px solid !important;
  }
}

.btn-header-cuenta{
  font-family: var(--primary-font);
  border-radius: 5px;
  padding: 0 20px 0 0;
  height: 40px; 
  font-weight: 600;
  transition: all 0.5s;

  i{
    font-size: 1.2em;
  }

  @mixin modoActivo{
    color: var(--onprimary-color);
    $themes:(
      "clasico":(
        background-color: #F1B70C,
        border: none
      ),
      "alto-contraste":(
        background-color: var(--secondary-color),
        border: 1px solid var(--onsecondary-color)
      )
    );
    @include ThemesProperties($themes); 
  }

  $themes:(
    "clasico":(
      background-color: #DCDDED
    ),
    "alto-contraste":(
      background-color: var(--onprimary-color)
    )
  );
  @include ThemesProperties($themes); 

  &.active-cuenta{
    @include modoActivo();

    > .arrow{
      transform: rotate(180deg);
    }
  }
  &:hover{
    @include modoActivo();
  }
  
  .btn-avatar{
    width: 80px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }
}

.btn-header-gris{
  font-family: var(--primary-font);
  border-radius: 5px;
  padding: 0 10px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s;
  display: flex;
  align-items: center;

  @mixin modoActivo{
    $themes:(
      "clasico":(
        background-color: var(--secondary-color),
        border: none,
        color: var(--onsecondary-color)
      ),
      "alto-contraste":(
        background-color: var(--secondary-color),
        border: 1px solid var(--onsecondary-color),
        color: var(--onsecondary-color)
      )
    );
    @include ThemesProperties($themes); 
  }

  &.active{
    @include modoActivo();

    > .arrow{
      transform: rotate(180deg);
    }
  }

  i{
    font-size: 1.2em;
  }

  .fa-universal-access{
    font-size: 1.5em;
  }

  $themes:(
    "clasico":(
      background-color: #DCDDED,
      color: var(--onprimary-color)
    ),
    "alto-contraste":(
      background-color: var(--onprimary-color),
      color: var(--onprimary-inverse)
    )
  );
  @include ThemesProperties($themes); 

  &:hover{
    @include modoActivo();
  }
}

.btn-header-amarillo{
  font-family: var(--primary-font);
  border-radius: 5px;
  width: 100px;
  height: 40px;
  font-weight: 600;
  background-color: var(--primary-inverse);
  color: var(--onprimary-inverse);
  display: flex;
  justify-content: center;
  align-items: center;

  @mixin modoActivo{
    background-color: var(--secondary-color);
    color: var(--onsecondary-color) !important;
    $themes:(
      "alto-contraste":(
        border: 1px solid var(--onsecondary-color)
      )
    );
    @include ThemesProperties($themes); 
  }

  &:hover{
    @include modoActivo();
  }

  &.active{
    @include modoActivo();
  }
}

.header-logo{
  img{
    display: block;
    height: 45px;
    margin: auto;
  }
}

.icon-buzon{
  width: 23px;
  height: 23px;
  background-image: url('/img/icons/campana.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-buzon{
  font-family: var(--primary-font);
  border-radius: 5px;
  padding: 0 15px;
  height: 40px;
  font-weight: 600;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  $themes:(
    "clasico":(
      background-color: #DCDDED
    ),
    "alto-contraste":(
      background-color: var(--onprimary-color)
    )
  );
  @include ThemesProperties($themes); 
  
  @mixin modoActivo{
    color: var(--onsecondary-color) !important;
    $themes:(
      "clasico":(
        background-color: var(--secondary-color),
        border: none
      ),
      "alto-contraste":(
        background-color: var(--secondary-color),
        border: 1px solid var(--onsecondary-color)
      )
    );
    @include ThemesProperties($themes); 

    .icon-buzon{
      background-image: url('/img/icons/campana-active.svg');
      $themes:(
        "alto-contraste":(
          background-image: url('/img/icons/campana-contraste.svg')
        )
      );
      @include ThemesProperties($themes); 
    }
  }
  
  &:hover{
    @include modoActivo();
  }

}

@media screen and (min-width: 768px) {
  header {
    .slash2 {
      display: inline-block;
    }
  }
}

@media only screen and (min-width: 992px) {
  .mm-brand {
    max-width: 115px;
  }

  .search-menu {
    flex-direction: row;
  }

  .search-input {
    border: 1px solid var(--onbackground-fourth) !important;
    font-weight: 700;

    +i {
      color: var(--onbackground-primary);
    }

    &:focus {
      border-color: var(--onbackground-fourth);
      box-shadow: 0px 1px 1px rgba(225, 225, 225, 0.075) inset, 0px 0px 8px rgba(200, 200, 200, 0.5);
    }

    &::placeholder {
      color: var(--onbackground-primary);
      font-weight: 500;
    }
  }
}

@media only screen and (min-width: 992px) {
  .width-options2{width: 20%;}
}

@media only screen and (min-width: 1400px) {
  .mm-brand {
    max-width: 150px;
  }

  .width-explore,
  .width-options2{width: 40%;}
}

@media only screen and (min-width: 1540px) {
  .width-options{width: 40%;}
}