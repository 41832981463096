/**
*En este archivo se encuentran los estilos de la página de Blog y entrada del blog
*/

/*-------------------------------------------*\
Estilos de la página principal del blog
\*-------------------------------------------*/

.blog-contenedor {
  .search-input {
    border: 2px solid var(--secondary-inverse) !important;
    padding: 18px 15px !important;
    border-radius: 25px;
  }

  font-family: $secondary-font;
}

.blog-container {
  max-width: 1500px;
}

.blog-entrada {
  a {
    display: block;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 10px;
    
    img {
      min-height: 401px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      $themes: ("clasico":(border: 1px solid aliceblue),
        "alto-contraste":(border: 1px solid var(--primary-inverse)),
      );

      @include ThemesProperties($themes);
    }

    &:hover {
      $themes: ("clasico":(color: var(--blue-color)),
        "alto-contraste":(color: var(--onblue-color)),
      );

      @include ThemesProperties($themes);

    }
  }

  .btn-secondary {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }

  .date {
    font-size: 0.9em;
    color: var(--onbackground-secondary);
  }
}

.blog-tags {
  border: 1px solid var(--secondary-inverse);
  border-radius: 10px;
  line-height: 1.2;

  h3 {
    border-bottom: 2px solid var(--onprimary-color);
  }

  a {
    color: var(--secondary-inverse);
  }
}

.blog-mailchimp {
  background-color: #000;
  color: var(--onsecondary-color);
  border-radius: 15px;
  font-size: 0.8em;
  line-height: 1.2;

  $themes: ("clasico":(border-style:none),
    "alto-contraste":(border-style: solid,
      border-width: 1px,
      border-color: var(--onsecondary-color)),
  );

  @include ThemesProperties($themes);

  .btn {
    font-size: 0.9em;
  }

  .footer-email {
    margin-top: 8px;
    border: none;
    outline: none;
    height: 32px;
    border-radius: 5px;
  }
}

.blog-accordion {
  position: relative;
  border: 1px solid var(--secondary-inverse);
  font-size: 0.95em !important;

  h3 {
    font-size: 1em !important;
    font-family: $secondary-font !important;
  }

  .list-main-item {
    .list-selector {
      font-weight: bold;
      font-family: var(--secondary-font);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--onbackground-secondary);
    }
  }
}

.item-active {
  border-left: 6px solid var(--secondary-inverse) !important;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
}

/*-------------------------------------------*\
Estilos de la entrada seleccionada
\*-------------------------------------------*/
.blog-entrada-container {
  font-family: $secondary-font !important;
  background-color: var(--background-color);
  color: var(--onbackground-primary);
}

.blog-image {
  max-height: 350px;
  border-radius: 25px;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.blog-contenido {
  background-color: var(--surface-color);
  margin-top: -3em;
  border-top: 7px solid var(--secondary-color);
  z-index: 99;
  position: relative;

  $themes: (
    "clasico":(
      border: none,
    ),
    "alto-contraste":(
      border: 1px solid,
      border-color: var(--onbackground-primary)
    ),
  );

  @include ThemesProperties($themes);
}

.entrada-title {
  font-size: 2.4em;
  line-height: 1;
}

.entrada-description {
  color: var(--onbackground-primary);
  font-size: 1.2em;
  border-bottom: 1px solid var(--onbackground-secondary);
}
