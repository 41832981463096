/*=====================================================
    CONFIGACCESIBILIDAD
    Este archivo define los estilos del panel de 
    accesibilidad.
 ====================================================== */

.menu-accesibilidad-flotante{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

.menu-accesibilidad {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: var(--secondary-color);
  color: var(--onsecondary-color);
  overflow: hidden;
  padding: .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: solid 2px var(--secondary-inverse);
  -webkit-transition: max-height .3s, padding .3s, visibility .3s;
  -o-transition: max-height .3s, padding .3s, visibility .3s;
  transition: max-height .3s, padding .3s, visibility .3s;


  &.expanded {
    max-height: 400px;
    visibility: visible;
  }

  &.collapsed {
    max-height: 0px;
    padding: 0;
    visibility: hidden;
    border: none;
  }

  &>.item {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 1rem;

    &>.label {
      margin-right: 1rem;
    }
  }

  &>.reset-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: .25rem;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: var(--primary-inverse);
    color: var(--onprimary-inverse);
    font-weight: bold;
    border-radius: .5rem;
  }


  .close-button {
    background: none;
    border: none;
    outline: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--onsecondary-color);
    line-height: 0;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s;

    &:hover {
      color: var(--primary-inverse);
    }
  }

  .text-fontSize {
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .range-slider__range_settings {
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    border-radius: 5px;
    border: solid 2px var(--secondary-inverse);
    background: var(--background-color);
    outline: none;
    padding: 0 !important;
    margin: 0;
    position: relative;
  }

  .range-slider__range_settings::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 0.5px solid var(--onprimary-color);
    background: var(--primary-color);
    cursor: pointer;
    -webkit-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;

    &:focus {
      outline: none !important;
    }
  }

  .range-slider__range_settings::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0.5px solid var(--onprimary-color);
    border-radius: 50%;
    background: var(--primary-color);
    cursor: pointer;
    z-index: 2000;
    -moz-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;

  }

  .range-slider__range_settings::-webkit-slider-thumb:hover {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings::-moz-range-thumb:hover {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings:active::-webkit-slider-thumb {
    background: var(--green-color);
    outline: none !important;
  }

  .range-slider__range_settings:active::-moz-range-thumb {
    background: var(--green-color);
    outline: none !important;
  }

  .wa-select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
    appearance: menulist;
    background: var(--background-color);
    color: var(--onbackground-primary);
    border: solid 2px var(--secondary-inverse);
    padding: 2px 5px;
    cursor: pointer;
    outline: none !important;
    font-size: 16px;

    option {
      font-size: 16px;
    }
  }
}

@media screen and (max-width:576px) {
  .menu-accesibilidad {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    &>.item {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      margin-bottom: 1rem;
    }

    &>.reset-button {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      -ms-grid-column-align: center;
      justify-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    &>.close-button {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }
  }
}

@media screen and (min-width:577px) {
  .menu-accesibilidad {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;

    &>.item {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1rem;

    }

    &>.reset-button {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      -ms-grid-column-align: center;
      justify-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    &>.close-button {
      -ms-grid-row: 1;
      grid-row-start: 1;
      -ms-grid-column-align: end;
      justify-self: end;
    }
  }
}

@media screen and (min-width:800px) {
  .menu-accesibilidad {
    -ms-grid-columns: 1fr 1fr 1fr auto auto;
    grid-template-columns: 1fr 1fr 1fr auto auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &>.item {
      -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
      margin-bottom: 0;
    }

    &>.close-button {
      -ms-grid-row: auto;
      grid-row-start: auto;
      -ms-grid-column-align: auto;
      justify-self: auto;
    }
  }
}

@media screen and (min-width:1600px) {
  .menu-accesibilidad {
    -ms-grid-columns: 1fr 1fr 1fr auto auto;
    grid-template-columns: 1fr 1fr 1fr auto auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &>.item {
      max-width: 400px;
      -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
      margin-bottom: 0;
    }
  }
}